"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckBox = void 0;

var react_1 = __importStar(require("react"));

var identity_1 = require("../../../identity");

exports.CheckBox = function (_a) {
  var label = _a.label,
      value = _a.value,
      _onChange = _a.onChange,
      checked = _a.checked,
      _b = _a.theme,
      theme = _b === void 0 ? identity_1.ThemeLight : _b;

  var _c = react_1.useState(checked),
      selected = _c[0],
      setSelected = _c[1];

  react_1.useEffect(function () {
    return setSelected(checked);
  }, [checked]);
  return react_1["default"].createElement("div", {
    className: "mt-4"
  }, react_1["default"].createElement("div", {
    className: "flex items-center"
  }, react_1["default"].createElement("input", {
    id: value,
    value: value,
    type: "checkbox",
    checked: selected,
    onChange: function onChange(_a) {
      var checked = _a.target.checked;
      setSelected(checked);

      _onChange(value, checked);
    },
    className: "form-checkbox h-4 w-4 " + (theme ? theme.form.textColor : "text-gray-800") + " transition duration-150 ease-in-out"
  }), react_1["default"].createElement("label", {
    htmlFor: value,
    className: "ml-3"
  }, react_1["default"].createElement("span", {
    className: "block text-sm leading-5 font-medium " + (theme ? theme.form.textColor : "text-gray-800")
  }, label))));
};