"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useResize = void 0;

var react_1 = require("react");

exports.useResize = function () {
  var _a = react_1.useState(window.innerWidth),
      windowWidth = _a[0],
      setWindowWidth = _a[1];

  var _b = react_1.useState(window.innerHeight),
      windowHeight = _b[0],
      setWindowHeight = _b[1];

  react_1.useEffect(function () {
    var handleResize = function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return {
    windowWidth: windowWidth,
    windowHeight: windowHeight
  };
};