"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isMobileView = void 0;

var useResize_1 = require("./useResize");

exports.isMobileView = function (width) {
  if (typeof window !== 'undefined') {
    return useResize_1.useResize().windowWidth <= width;
  }

  return false;
};