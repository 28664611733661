"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentStyle = exports.footerStyle = exports.headerStyle = exports.wrapperStyle = void 0;

exports.wrapperStyle = function (theme) {
  return theme ? theme.body.backgroundColor + " " + theme.body.borderColor + " " + theme.body.textColor + " overflow-hidden shadow-lg rounded-lg" : "bg-gray-100 border-gray-300 text-gray-800 overflow-hidden shadow-lg rounded-lg";
};

exports.headerStyle = function (theme) {
  return theme ? theme.body.borderColor + " " + theme.body.textColor : "border-gray-300 text-gray-800 border-b px-4 py-5 sm:px-6";
};

exports.footerStyle = function (theme) {
  return theme ? theme.body.borderColor + " " + theme.body.textColor : "border-gray-300 text-gray-800 border-t px-4 py-5 sm:px-6";
};

exports.contentStyle = function () {
  return "px-4 py-5 sm:p-6";
};