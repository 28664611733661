export const PAGE_NAME = 'Basic Frontend';
export const PAGE_AUTHOR = 'responsive IT';
export const PAGE_DESCRIPTION = 'finest full stack development';
export const PAGE_TOPIC = 'finest reactive mobile web application';
export const PAGE_KEYWORDS = 'reactjs, css3, html5, responsive';

export const BG_IMAGE =
  'https://cdn.responsive-it.biz/assets/bg-images/Website-Design-Background.png';

export const ICON = 'https://cdn.responsive-it.biz/assets/icons/icon.png';

export const MOBILE_VIEW_WIDTH = 640;
