"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spotlight = exports.PictogramOrientation = void 0;

var react_1 = __importStar(require("react"));

var elements_1 = require("../../elements");

var identity_1 = require("../../identity");

var typings_1 = require("../../typings");

var PictogramOrientation;

(function (PictogramOrientation) {
  PictogramOrientation[PictogramOrientation["left"] = 0] = "left";
  PictogramOrientation[PictogramOrientation["right"] = 1] = "right";
})(PictogramOrientation = exports.PictogramOrientation || (exports.PictogramOrientation = {}));

exports.Spotlight = function (_a) {
  var title = _a.title,
      content = _a.content,
      pictogram = _a.pictogram,
      _b = _a.pictogramSize,
      pictogramSize = _b === void 0 ? identity_1.PictogramSize.md : _b,
      _c = _a.orientation,
      orientation = _c === void 0 ? typings_1.Orientation.left : _c,
      theme = _a.theme,
      elementId = _a.elementId;

  var _d = react_1.useState(window.innerWidth),
      windowWidth = _d[0],
      setWindowWidth = _d[1];

  var pictogramResponsiveSize = windowWidth >= 650 ? pictogramSize : identity_1.PictogramSize.lg;
  var showRegularText = windowWidth >= 650 ? true : false;
  var titleText = showRegularText ? "" : content;
  react_1.useEffect(function () {
    var handleWindowResize = function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return function () {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  var contentBlock = react_1["default"].createElement("div", {
    title: titleText,
    className: "flex flex-col w-full m-2 md:m-4 " + (orientation === typings_1.Orientation.left ? "ml-2 md:ml-4" : "mr-2 md:mr-4")
  }, react_1["default"].createElement(elements_1.TitleSub, {
    theme: theme
  }, title), showRegularText && react_1["default"].createElement(elements_1.TextRegular, {
    theme: theme
  }, content));
  var pictogramBlock = react_1["default"].createElement("div", {
    className: "m-2 md:m-4 " + (theme ? theme.body.textColor : "text-gray-800")
  }, react_1["default"].createElement(identity_1.Pictogram, {
    name: pictogram,
    size: pictogramResponsiveSize
  }));
  var style = "flex flex-row items-center w-full " + (theme ? theme.body.backgroundColor : "bg-gray-100");
  return react_1["default"].createElement(react_1["default"].Fragment, null, orientation === typings_1.Orientation.left ? react_1["default"].createElement("div", {
    className: style,
    id: elementId
  }, pictogramBlock, contentBlock) : react_1["default"].createElement("div", {
    className: style,
    id: elementId
  }, contentBlock, pictogramBlock));
};