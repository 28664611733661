"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonSize = exports.ButtonType = void 0;
var ButtonType;

(function (ButtonType) {
  ButtonType[ButtonType["primary"] = 0] = "primary";
  ButtonType[ButtonType["secondary"] = 1] = "secondary";
  ButtonType[ButtonType["tertiary"] = 2] = "tertiary";
  ButtonType[ButtonType["transparent"] = 3] = "transparent";
  ButtonType[ButtonType["alarm"] = 4] = "alarm";
  ButtonType[ButtonType["special"] = 5] = "special";
})(ButtonType = exports.ButtonType || (exports.ButtonType = {}));

var ButtonSize;

(function (ButtonSize) {
  ButtonSize[ButtonSize["small"] = 0] = "small";
  ButtonSize[ButtonSize["normal"] = 1] = "normal";
})(ButtonSize = exports.ButtonSize || (exports.ButtonSize = {}));