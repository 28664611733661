"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Topbar = void 0;

var react_1 = __importStar(require("react"));

var horizontal_navbar_1 = require("./horizontal-navbar");

exports.Topbar = function (_a) {
  var children = _a.children,
      footer = _a.footer,
      menuItems = _a.menuItems,
      branding = _a.branding,
      backgroundImage = _a.backgroundImage,
      theme = _a.theme;

  var _b = react_1.useState(false),
      isMenuVisible = _b[0],
      toggleMenuVisible = _b[1];

  if (typeof window !== "undefined" && backgroundImage) {
    // document.body.style.background = `linear-gradient(to bottom, #0b3536 0%, rgba(125, 185, 232, 0)), url(${backgroundImage})`;
    document.body.style.backgroundImage = "linear-gradient(to bottom, #0b3536 0%, rgba(125, 185, 232, 0)), url(" + backgroundImage + ")";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundSize = "cover";
  }

  return react_1["default"].createElement("div", {
    className: "flex flex-col w-full min-h-screen",
    id: "body-wrapper"
  }, react_1["default"].createElement(horizontal_navbar_1.HorizontalNavbar, {
    isMenuVisible: isMenuVisible,
    toggleMenuVisible: toggleMenuVisible,
    menuItems: menuItems,
    branding: branding,
    theme: theme
  }), react_1["default"].createElement("main", {
    className: "flex flex-col flex-1 relative z-0",
    id: "main-container",
    tabIndex: 0
  }, children), footer);
};