"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormFieldValidation = exports.typeOfNumberCheck = exports.typeOfStringCheck = exports.FormFieldType = void 0;
var FormFieldType;

(function (FormFieldType) {
  FormFieldType[FormFieldType["String"] = 0] = "String";
  FormFieldType[FormFieldType["Number"] = 1] = "Number";
  FormFieldType[FormFieldType["Password"] = 2] = "Password";
  FormFieldType[FormFieldType["Email"] = 3] = "Email";
  FormFieldType[FormFieldType["Dropdown"] = 4] = "Dropdown";
})(FormFieldType = exports.FormFieldType || (exports.FormFieldType = {}));

exports.typeOfStringCheck = function (value) {
  return typeof value === 'string';
};

exports.typeOfNumberCheck = function (value) {
  return typeof value === 'number';
};

exports.FormFieldValidation = function (fieldType, fieldValue, minLength, maxLength) {
  var fieldMinLength = minLength ? minLength : 1;
  var fieldMaxLength = maxLength ? maxLength : 64;

  switch (fieldType) {
    case FormFieldType.Email:
      return exports.typeOfStringCheck(fieldValue) && fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    case FormFieldType.Password:
      return exports.typeOfStringCheck(fieldValue) && fieldValue.length >= fieldMinLength && fieldValue.length <= fieldMaxLength;

    case FormFieldType.String:
      return exports.typeOfStringCheck(fieldValue) && fieldValue.length >= fieldMinLength && fieldValue.length <= fieldMaxLength;

    case FormFieldType.Dropdown:
      return exports.typeOfStringCheck(fieldValue) && fieldValue.length >= fieldMinLength && fieldValue.length <= fieldMaxLength;

    case FormFieldType.Number:
      return exports.typeOfNumberCheck(fieldValue) && fieldValue > 0;

    default:
      return false;
  }
};