"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAnalyticsSet = exports.getLanguage = exports.isCookieSet = exports.clearLocalStorage = exports.removeStorageItem = exports.getStorageItem = exports.setStorageItem = exports.sleep = exports.COOKIE_KEY = void 0;
exports.COOKIE_KEY = 'master_cookie';

exports.sleep = function (milliseconds) {
  var date = Date.now();
  var currentDate = null;

  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

exports.setStorageItem = function (key, data) {
  localStorage.setItem(key, data);
};

exports.getStorageItem = function (key) {
  return localStorage.getItem(key);
};

exports.removeStorageItem = function (key) {
  localStorage.removeItem(key);
};

exports.clearLocalStorage = function () {
  localStorage.clear();
};

exports.isCookieSet = function () {
  var cookie = exports.getStorageItem(exports.COOKIE_KEY);

  if (!cookie) {
    return false;
  }

  return true;
};

exports.getLanguage = function () {
  if (typeof window !== 'undefined') {
    return navigator.language;
  }

  return null;
};

exports.isAnalyticsSet = function () {
  var cookie = exports.getStorageItem(exports.COOKIE_KEY);

  if (!cookie) {
    return true;
  } else {
    var value = JSON.parse(cookie);
    return value.analytics === true;
  }
};