"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openUrl = exports.openWindow = exports.getUrlParameter = void 0;

exports.getUrlParameter = function (name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

exports.openWindow = function (url) {
  window.open(url);
};

exports.openUrl = function (url) {
  window.location.href = url;
};