"use strict"; // -------------------------------------------------------------------------------
// titles ->
// -------------------------------------------------------------------------------

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defTextPadding = exports.defTextMargin = exports.defTopMaring = exports.defButtonNormal = exports.defButtonSmall = exports.defFormError = exports.defCaption = exports.defLink = exports.defComment = exports.defLabel = exports.defLegend = exports.defTextLead = exports.defTextRegular = exports.defTitle = exports.defTitleAbstract = exports.defTitleSub = void 0;
exports.defTitleSub = 'text-2xl font-semibold md:text-2xl md:font-bold tracking-wider leading-normal';
exports.defTitleAbstract = 'text-xl font-semibold md:text-xl md:font-bold uppercase tracking-wider leading-normal';
exports.defTitle = 'text-3xl md:text-3xl font-semibold md:font-bold tracking-wider leading-normal'; // -------------------------------------------------------------------------------
// texts ->
// -------------------------------------------------------------------------------

exports.defTextRegular = 'text-lg font-normal md:text-lg md:font-medium tracking-wide leading-normal';
exports.defTextLead = 'text-xl font-medium md:text-xl md:font-semibold tracking-wider leading-normal';
exports.defLegend = 'text-lg font-medium md:text-lg md:font-medium leading-normal';
exports.defLabel = 'text-lg font-medium md:text-lg md:font-medium leading-normal';
exports.defComment = 'text-lg font-medium md:text-lg md:font-medium leading-normal italic';
exports.defLink = 'text-lg font-normal md:text-lg md:font-medium tracking-wide leading-normal';
exports.defCaption = 'text-lg font-normal md:text-lg md:font-medium leading-normal uppercase';
exports.defFormError = 'text-lg font-medium md:text-sm md:font-medium leading-normal'; // -------------------------------------------------------------------------------
// buttons
// -------------------------------------------------------------------------------

exports.defButtonSmall = 'text-lg leading-4';
exports.defButtonNormal = 'text-xl leading-5 font-medium'; // -------------------------------------------------------------------------------
// margin / padding
// -------------------------------------------------------------------------------

exports.defTopMaring = 'mt-4';

exports.defTextMargin = function (marginBottom, marginTop) {
  return (marginBottom ? 'mb-4' : '') + " " + (marginTop ? 'mt-4' : '');
};

exports.defTextPadding = function (paddingBottom, paddingTop) {
  return (paddingBottom ? 'pb-4' : '') + " " + (paddingTop ? 'pt-4' : '');
};