"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Orientation = void 0;
var Orientation;

(function (Orientation) {
  Orientation[Orientation["left"] = 0] = "left";
  Orientation[Orientation["right"] = 1] = "right";
})(Orientation = exports.Orientation || (exports.Orientation = {}));