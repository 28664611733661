"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styleSpecial = exports.styleAlarm = exports.styleTransparent = exports.styleTertiary = exports.styleSecondary = exports.stylePrimary = exports.buttonBorderStyle = exports.buttonTextStyle = exports.buttonPadding = exports.buttonWrapper = void 0;

var identity_1 = require("../../identity");

var button_types_1 = require("./button-types");

exports.buttonWrapper = function (size) {
  return "inline-flex " + (size === button_types_1.ButtonSize.normal ? "shadow-md rounded-lg hover:shadow-lg" : "shadow-md rounded-md hover:shadow-lg") + " ";
};

exports.buttonPadding = function (size) {
  return "" + (size === button_types_1.ButtonSize.normal ? "w-40 px-4 py-2" : "w-24 px-2 py-1");
};

exports.buttonTextStyle = function (size) {
  return "" + (size === button_types_1.ButtonSize.normal ? identity_1.defButtonNormal : identity_1.defButtonSmall);
};

exports.buttonBorderStyle = function (size) {
  return "" + (size === button_types_1.ButtonSize.normal ? "rounded-md" : "rounded");
};

exports.stylePrimary = function (size) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border border-gray-300 " + exports.buttonBorderStyle(size) + " text-gray-700 " + exports.buttonTextStyle(size) + " bg-gray-100 hover:text-gray-900 hover:shadow-lg focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-100 transition ease-in-out duration-150";
};

exports.styleSecondary = function (size) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border border-transparent " + exports.buttonBorderStyle(size) + " text-white " + exports.buttonTextStyle(size) + " bg-green-400 hover:bg-green-300 hover:shadow-lg focus:outline-none focus:border-green-500 focus:shadow-outline-green active:bg-green-400 transition ease-in-out duration-150";
};

exports.styleTertiary = function (size) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border border-transparent " + exports.buttonBorderStyle(size) + " text-white " + exports.buttonTextStyle(size) + " bg-indigo-600 hover:bg-indigo-500 hover:shadow-lg focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150";
};

exports.styleTransparent = function (size, theme) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border " + (theme ? theme.body.borderColor : "border-transparent") + " " + exports.buttonBorderStyle(size) + " " + (theme ? theme.body.textColor : "text-gray-800") + " " + exports.buttonTextStyle(size) + " bg-transparent hover:text-gray-900 hover:bg-gray-100 hover:shadow-lg focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-transparent transition ease-in-out duration-150";
};

exports.styleAlarm = function (size) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border border-transparent " + exports.buttonBorderStyle(size) + " text-white " + exports.buttonTextStyle(size) + " bg-red-600 hover:bg-red-500 hover:text-gray-100 hover:shadow-lg focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-red-500 transition ease-in-out duration-150";
};

exports.styleSpecial = function (size) {
  return "inline-flex justify-center items-center " + exports.buttonPadding(size) + " border-1 border-gray-400 " + exports.buttonBorderStyle(size) + " text-gray-100 " + exports.buttonTextStyle(size) + " bg-sred-100 hover:text-gray-200 hover:bg-sred-200 hover:shadow-lg focus:outline-none focus:border-gray-500 focus:shadow-outline-gray active:bg-sred-200 transition ease-in-out duration-150";
};