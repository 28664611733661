"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RadioButtonGroup = void 0;

var react_1 = __importStar(require("react"));

var identity_1 = require("../../../identity");

var form_base_1 = require("../form-base");

var radiobutton_1 = require("./radiobutton");

exports.RadioButtonGroup = function (_a) {
  var id = _a.id,
      name = _a.name,
      label = _a.label,
      legend = _a.legend,
      options = _a.options,
      _onChange = _a.onChange,
      selectedItem = _a.selectedItem,
      _b = _a.theme,
      theme = _b === void 0 ? identity_1.ThemeLight : _b;

  var _c = react_1.useState(selectedItem || null),
      selected = _c[0],
      setSelected = _c[1];

  react_1.useEffect(function () {
    return setSelected(selectedItem || null);
  }, [selectedItem]);
  return react_1["default"].createElement(form_base_1.FormBase, {
    label: label,
    htmlFor: id,
    legend: legend,
    theme: theme
  }, react_1["default"].createElement("fieldset", {
    id: id,
    name: name
  }, options.map(function (item) {
    return react_1["default"].createElement(radiobutton_1.RadioButton, {
      key: item.value,
      value: item.value,
      label: item.label,
      theme: theme,
      checked: selected === item.value,
      onChange: function onChange() {
        setSelected(item.value);
        _onChange && _onChange(item.value);
      }
    });
  })));
};